<template>
<!--  <v-breadcrumbs  class="breadcrumbs" :items="items"></v-breadcrumbs>-->
  <div  class="crumbs">
    <div v-if="stack.length > 0" class="crumbs__title" >
      {{ stack[0].label }}
    </div>
    <ul v-if="stack.length > 0" class="pa-0">
      <li v-for="(item, index) in stack" :key="item.label" class="crumbs__item" :class="item.class">
        <template v-if="index === 0">
          {{ item.label}}
        </template>
        <router-link v-else :to="{ name: item.name }" class="crumbs__link">
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name:    'Crumbs',
  data() {
    return {
      stack: [],
      // items: [
      //   {
      //     text: 'Dashboard',
      //     disabled: false,
      //     to: '/',
      //   },
      //   {
      //     text: 'Finance',
      //     disabled: false,
      //     href: '/dashboard/finance',
      //   }
      // ]
    }
  },
  mounted() {
    this.buildStack()
  },
  methods: {
    buildStack() {
      this.stack = []
      this.addPage(this.$router.currentRoute)
      this.stack = this.stack.reverse()
    },
    addPage(route, first) {
      this.stack.push({
        name:  route.name,
        label: route.meta?.breadcrumb?.label?.trim() || route.name?.replace('.', ' '),
        class: 'crumbs__item--' + (typeof first === 'undefined' ? 'current' : 'parent')
      })
      if (route.meta?.breadcrumb?.parent) {
        const parent = this.getRoute(route.meta.breadcrumb.parent)
        this.addPage(parent, true)
      }
    },
    getRoute(name) {
      return this.$router.options.routes.find(route => route.name === name)
    }
  }
}
</script>

<style lang="scss">
  .crumbs {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      text-transform: capitalize;
    }
    ul {
       display:         flex;
       flex-direction:  row;
       list-style-type: none;
       margin-left: 2px;
       padding:         0;
       flex-wrap:       wrap;
       font-size: 13px;
       line-height: 18px;
    }
    &__link {
      word-break: break-word;
      text-decoration: none;
      text-transform: capitalize;
      color: #DF0E77 !important;
    }
    &__item--parent::after {
      content: '>';
      margin-right: 5px;
    }
  }
</style>